$text-font-size: 11px;

.fiche:hover{
    background: #6778A2;
    padding-right: 20;
    border-radius: 30px;
    color: white;
    font-weight: bold
}

.newFiche:hover{
    background: #fff;
    border: 1px solid #FF7A21;
}

.newFiche{
    background: linear-gradient(178deg,#EF3F75  ,  #FF7A21);;
}

#formulaire-profil{
    font-size: 13px;
    height: 12px;
    width: 95%;
    margin: 0 auto;
}

#formulaire-profil2{
    font-size: 13px;
    width: 95%;
    margin: 0 auto;
}

// FAIT
.entete-simulateur .c-subheader,
.entete-client .c-subheader,
.entete-fiche-thematique .c-subheader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0;
    padding-top: 6px;
    padding-bottom: 6px
}

// FAIT
.entete-simulateur .bloc-fil-ariane a,
.entete-client .bloc-fil-ariane a,
.entete-fiche-thematique .bloc-fil-ariane a{
    font-size: $text-font-size;
    color: #9C9C9C;
}

// Hauteur de chaque étape de la file d'Ariane
.entete-simulateur .bloc-fil-ariane .breadcrumb-item,
.entete-client .bloc-fil-ariane .breadcrumb-item,
.entete-fiche-thematique .bloc-fil-ariane .breadcrumb-item{
    height: 18px;
}

// Le //
.entete-simulateur .bloc-fil-ariane .breadcrumb-item::before,
.entete-simulateur .bloc-fil-ariane .breadcrumb-item.active::before,
.entete-client .bloc-fil-ariane .breadcrumb-item::before,
.entete-client .bloc-fil-ariane .breadcrumb-item.active::before,
.entete-fiche-thematique .bloc-fil-ariane .breadcrumb-item::before,
.entete-fiche-thematique .bloc-fil-ariane .breadcrumb-item.active::before{
    height: 18px;
    font-size: $text-font-size;
    color: #9C9C9C;
    font-weight: normal;
}

// FAIT
.entete-simulateur .bloc-fil-ariane a:hover{
    color: #809FB8;
    font-weight: bold;
    text-decoration: none;
}

// FAIT
.entete-client .bloc-fil-ariane a:hover{
    color: #8E5FA8;
    font-weight: bold;
    text-decoration: none;
}

// FAIT
.entete-fiche-thematique .bloc-fil-ariane a:hover{
    color: #EF95AA;
    font-weight: bold;
    text-decoration: none;
}

// FAIT
.entete-simulateur .c-subheader{
    height: 60px;
    background: #E0EBF3 !important;
}

// FAIT
.entete-client .c-subheader{
    height: 60px;
    background: #EBD6F3 !important;
}

// FAIT
.entete-fiche-thematique .c-subheader{
    background: #FFE5EA !important;
    position: relative;
    height: 60px;
    // padding: 0;
}






// FAIT
.entete-simulateur .c-subheader,
.entete-client .c-subheader,
.entete-fiche-thematique .c-subheader,
.c-subheader-nav {
    min-height: 15px !important;
}







// FAIT
.entete-simulateur .breadcrumb-item.active,
.entete-client .breadcrumb-item.active,
.entete-fiche-thematique .breadcrumb-item.active{
    font-size: $text-font-size;
    font-weight: bold;
}

// FAIT
.entete-simulateur .breadcrumb-item.active{
    color: #809FB8;
}

// FAIT
.entete-client .breadcrumb-item.active{
    color: #8E5FA8;
}

// FAIT
.entete-fiche-thematique .breadcrumb-item.active{
    color: #EF95AA;
}

// FAIT
.entete-simulateur .personnalisation-simulation,
.entete-client .personnalisation-simulation,
.entete-fiche-thematique .personnalisation-simulation{
    position: fixed;
    width: 300px;
    background: #FFFFFF;
    top: 117px;
    right: 0;
    z-index: 1;
    border-radius: 6px;
    padding: 20px;
    border: 1px solid #E9E9E9
}

// FAIT
.entete-simulateur .bouton-personnalisation-information-simulation:hover,
.entete-client .bouton-personnalisation-information-simulation:hover,
.entete-fiche-thematique .bouton-personnalisation-information-simulation:hover{
    transform: scale(1.05);
    transition: 0.6s
}

.personnalisation-enregistrement:hover{
    background: #EBD6F3;
    border-radius: 15px
}

.texte-personnalisation-enregistrement{
    display: flex;
    align-items: center;
    width: 210px;
    color: rgb(142,95,168);
    font-weight: bold;
    height: 30px;

    &-content {
      display: flex;
      flex-direction: column;
    }
}

.shortcut-simulation {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-right: 30px;
  position: relative;

  &:hover {
    background: #D6E0EA !important;
    border-radius: 50%;
    transition: .5s all;
  }

  &-btn {
    cursor: pointer;
  }

  &-content {
    position: absolute;
    top: 50px;
    left: -48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    gap: 25px;
    border-radius: 6px;
    padding: 15px;
    border: 1px solid #E9E9E9;
    width: 134px;

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:hover {
        opacity: .7;
        transition: .5s all;
      }

      &-label {
        display: 'flex';
        justify-content: 'center';
        align-items: 'center';
        text-align: center;
      }
    }
  }

}
.custom-select:disabled {
  padding-right: 26px !important;
}

@media (max-width: 780px){
    .texte-personnalisation-enregistrement{
        width: 100px;
    }
}
